import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import mainStore from "../app/main.store";

function AuthGuard({ children }: any) {
  const navigate = useNavigate();
  const loc = useLocation();

  useEffect(() => {
    if (loc.pathname === "/" && mainStore.user === undefined) {
      navigate("/identity/login");
    }

    if (loc.pathname === "/identity/login" && mainStore.user !== undefined) {
      navigate("/");
    }
  }, []);

  return children;
}

export default observer(AuthGuard);
