import { observer } from "mobx-react-lite";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import mainStore from "../../../app/main.store";
import { GenerateId } from "../../../services/generators";
import styles from "./Login.module.scss";

function Login() {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (fullName === "") return;

    const data = {
      _id: GenerateId(),
      fullName,
    };

    localStorage.setItem("user", JSON.stringify(data));
    mainStore.setUser(data);
    mainStore.socket.connect();
    navigate("/");
  }

  if (mainStore.user !== undefined) return null;

  return (
    <div className={styles.login}>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={fullName}
          placeholder="Enter your name"
          onChange={(e) => {
            setFullName(e.target.value);
          }}
        />
        <button>Login</button>
      </form>
    </div>
  );
}

export default observer(Login);
