export function GenerateId() {
  return (
    new Date()
      .toISOString()
      .replace("T", "")
      .replace("Z", "")
      .replace(".", "")
      .replaceAll("-", "")
      .replaceAll(":", "") + (Math.random() * 1000).toFixed()
  );
}
