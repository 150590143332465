import styles from "./NotFound.module.scss";

function NotFound() {
  return (
    <div className={styles.not_found}>
      <h1>Error 404 Not Found</h1>
    </div>
  );
}

export default NotFound;
