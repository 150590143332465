import { Route, Routes } from "react-router-dom";
import Chat from "./pages/chat/Chat";
import Default from "./pages/chat/Default";
import Direct from "./pages/chat/direct/Direct";
import Login from "./pages/identity/login/Login";
import NotFound from "./pages/not-found/NotFound";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Chat />}>
          <Route path="/" element={<Default />} />
          <Route path="direct/:userId" element={<Direct />} />
        </Route>
        <Route path="/identity/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
