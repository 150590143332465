import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthGuard from "./guard/AuthGuard";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthGuard>
      <App />
    </AuthGuard>
  </BrowserRouter>
  // </React.StrictMode>
);
