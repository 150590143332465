import { observer } from "mobx-react-lite";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IUser, ISendMessage, IMessage } from "../../../@types/interfaces";
import mainStore from "../../../app/main.store";
import { environment } from "../../../environment";
import { GenerateId } from "../../../services/generators";
import styles from "./Direct.module.scss";

function isMessageEmpty(msg: string) {
  return msg.replaceAll("\n", "").trim() === "";
}

function checkMessageDir(msg: string) {
  return (
    msg.replace("\n", "").trim()[0] &&
    msg
      .replace("\n", "")
      .trim()[0]
      .match(/[\u0621-\u064A]/i)
  );
}

function Direct() {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const messageListRef = useRef<HTMLDivElement>(null!);
  const { userId } = useParams();

  useEffect(() => {
    if (mainStore.user === undefined) return navigate("/identity/login");
    if (userId === undefined) return navigate("/");

    if (mainStore.currentDirectUser === undefined) {
      fetch(environment.base_url + `/getDirectUser/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          mainStore.setCurrentDirectUser(data);
        });
    }

    for (let i = 0; i < mainStore.directChatList.length; i++) {
      if (mainStore.directChatList[i].memberList.includes(userId) === true) {
        return mainStore.setCurrentDirectChat(mainStore.directChatList[i]);
      }
    }

    console.log("fetched");
    fetch(
      environment.base_url + `/getMessageList/${mainStore.user._id}/${userId}`
    )
      .then((res) => res.json())
      .then((data: IMessage[]) => {
        const newChat =
          data.length > 0
            ? {
                _id: data[0].chatId,
                memberList: [(mainStore.user as IUser)._id, userId],
                messageList: data,
              }
            : {
                _id: GenerateId(),
                memberList: [(mainStore.user as IUser)._id, userId],
                messageList: [],
              };
        mainStore.setCurrentDirectChat(newChat);

        mainStore.setDirectChatList([newChat, ...mainStore.directChatList]);
      });
  }, [userId]);

  function handleMessageSubmit() {
    if (mainStore.user === undefined) return navigate("/identity/login");
    if (isMessageEmpty(message)) return setMessage("");

    const to = mainStore.onlineUsersList.find(
      (x) =>
        mainStore.currentDirectChat?.memberList.includes(x._id) === true &&
        x._id !== mainStore.user?._id
    );

    const data: ISendMessage = {
      message: {
        from: mainStore.user._id,
        to: to?._id!!,
        body: message,
        createdAt: new Date().toISOString(),
      },
      user: to!!,
    };

    mainStore.socket.emit("send-message", data);
    setMessage("");
    messageListRef.current.scrollTop = 0;
  }

  if (mainStore.currentDirectChat === undefined) return null;

  return (
    <>
      {mainStore.currentDirectUser !== undefined && (
        <header className={styles.message_header}>
          <Link to={"/"}>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="white"
              className="bi bi-arrow-left-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
            </svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="white"
              className="bi bi-arrow-left-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z" />
            </svg>
          </Link>
          <h1>{mainStore.currentDirectUser.fullName}</h1>
        </header>
      )}

      <div className={styles.message_list} ref={messageListRef}>
        {mainStore.currentDirectChat.messageList.map((msg, i) => (
          <div
            key={i}
            className={
              styles.msg_container +
              " " +
              (msg.from === mainStore.user?._id ? styles.self : styles.others)
            }
          >
            {(i === 0 || i > 0) && <div className={styles.msg_date}></div>}
            <div className={styles.msg_item}>
              <p className={styles.msg_body}>{msg.body}</p>
              <div className={styles.msg_time}>
                {new Date(msg.createdAt).toLocaleTimeString()}
              </div>
            </div>
          </div>
        ))}
      </div>
      {mainStore.currentDirectUser !== undefined && (
        <div className={styles.message_footer}>
          <textarea
            autoFocus
            dir={checkMessageDir(message) ? "rtl" : "ltr"}
            value={message}
            onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === "Enter" && !e.altKey && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleMessageSubmit();
              }
            }}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <button
            onClick={() => {
              handleMessageSubmit();
            }}
          >
            Send
          </button>
        </div>
      )}
    </>
  );
}

export default observer(Direct);
