import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IMessage, IUser, IUserSocket } from "../../@types/interfaces";
import mainStore from "../../app/main.store";
import ChatSidebar from "../../components/chat_sidebar/ChatSidebar";
import Footer from "../../components/Footer/Footer";
import styles from "./Chat.module.scss";

function Chat() {
  const navigate = useNavigate();
  const loc = useLocation();
  const inMessageAud = useRef<HTMLAudioElement>(null!);
  const outMessageAud = useRef<HTMLAudioElement>(null!);

  useEffect(() => {
    if (mainStore.user === undefined) return navigate("/identity/login");

    mainStore.socket.emit("add-me-online", mainStore.user);

    mainStore.socket.io.on("reconnect", () => {
      mainStore.socket.emit("add-me-online", mainStore.user);
    });

    mainStore.socket.on("online-users", (list: IUserSocket[]) => {
      mainStore.setOnlineUsersList(list);
    });

    mainStore.socket.on("receive-message", (message: IMessage) => {
      mainStore.socketReceiveMessage(message);
      console.log(message);
      console.log(loc);
      console.log(loc.pathname.includes(message.from));
      console.log(message.to === mainStore.user?._id);
      if (message.to === mainStore.user?._id) {
        if (loc.pathname.includes(message.from)) {
          inMessageAud.current.play();
        } else {
          outMessageAud.current.play();
        }
      }
    });

    return () => {
      mainStore.socket.io.removeAllListeners();
      mainStore.socket.removeAllListeners();
      mainStore.socket.disconnect();
    };
  }, []);

  if (mainStore.user === undefined) return null;

  return (
    <main
      className={
        styles.chat + (loc.pathname === "/" ? "" : " " + styles.selected)
      }
    >
      <aside className={styles.sidebar}>
        <ChatSidebar />
        <Footer />
      </aside>

      <section className={styles.content}>
        <Outlet />
      </section>
      <audio ref={inMessageAud} hidden>
        <source src="/sounds/in_chat_notification.mp3" type="audio/mpeg" />
        <source src="/sounds/in_chat_notification.ogg" type="audio/ogg" />
      </audio>
      <audio ref={outMessageAud} hidden>
        <source src="/sounds/out_chat_notification.mp3" type="audio/mpeg" />
        <source src="/sounds/out_chat_notification.ogg" type="audio/ogg" />
      </audio>
    </main>
  );
}

export default observer(Chat);
