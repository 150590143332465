import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { IUserSocket } from "../../@types/interfaces";
import mainStore from "../../app/main.store";
import styles from "./ChatSidebar.module.scss";

function ChatSidebar() {
  const navigate = useNavigate();

  function handleSelectCurrentChat(directUser: IUserSocket) {
    mainStore.setCurrentDirectUser(directUser);
    navigate(`/direct/${directUser._id}`);
  }

  return (
    <>
      <div className={styles.header}>
        <h2>{mainStore.user?.fullName.toUpperCase()}</h2>
      </div>
      <div className={styles.online_users}>
        <div className={styles.users_list}>
          {mainStore.onlineUsersList.map(
            (item, i) =>
              item._id !== mainStore.user?._id && (
                <div
                  key={i}
                  className={styles.user_card}
                  onClick={() => {
                    handleSelectCurrentChat(item);
                  }}
                >
                  {item.fullName}
                  {item.online === true && (
                    <div className={styles.online_state}></div>
                  )}
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
}

export default observer(ChatSidebar);
