import { makeAutoObservable } from "mobx";
import { io } from "socket.io-client";
import { LS_Keys } from "../@types/enums";
import {
  IDirectChat,
  IMessage,
  IUser,
  IUserSocket,
} from "../@types/interfaces";
import { environment } from "../environment";

class MainStore {
  socket;
  user?: IUser;
  currentDirectUser?: IUserSocket;
  onlineUsersList: IUserSocket[] = [];
  currentDirectChat?: IDirectChat;
  directChatList: IDirectChat[] = [];

  constructor() {
    this.socket = io(environment.socket_url, {
      withCredentials: true,
      path: "/api/ws",
    });
    const user = localStorage.getItem(LS_Keys.user);
    this.user = user !== null ? JSON.parse(user) : undefined;
    makeAutoObservable(this);
  }

  // simple actions
  setUser(user?: IUser) {
    this.user = user;
  }

  setCurrentDirectUser(currentDirectUser?: IUserSocket) {
    this.currentDirectUser = currentDirectUser;
  }

  setOnlineUsersList(onlineUsersList: IUserSocket[]) {
    this.onlineUsersList = onlineUsersList;
  }

  setCurrentDirectChat(currentDirectChat?: IDirectChat) {
    this.currentDirectChat = currentDirectChat;
  }

  setDirectChatList(directChatList: IDirectChat[]) {
    this.directChatList = directChatList;
  }

  // custom actions
  addMessageToDirectChatList(msg: IMessage, chatIndex: number) {}

  addChatToDirectChatList(chat: IDirectChat) {
    this.directChatList.unshift(chat);
  }

  // socket actions
  socketReceiveMessage(message: IMessage) {
    for (let i = 0; i < this.directChatList.length; i++) {
      const ele = this.directChatList[i];
      if (
        ele.memberList.includes(message.from) === true &&
        ele.memberList.includes(message.to) === true
      ) {
        this.directChatList[i].messageList.unshift(message);
        this.directChatList[i].memberList.join() ===
          this.currentDirectChat?.memberList.join() &&
          (this.currentDirectChat = this.directChatList[i]);
        return;
      }
    }

    fetch(
      environment.base_url + `/getMessageList/${this.user?._id}/${message.from}`
    )
      .then((res) => res.json())
      .then((data: IMessage[]) => {
        this.directChatList.unshift({
          memberList: [(this.user as IUser)._id, message.from],
          messageList: data.length > 0 ? data : [message],
        });
      });
  }
}

const mainStore = new MainStore();

export default mainStore;
