const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const environment = {
  base_url:
    (isDev
      ? process.env.REACT_APP_BASE_URL_DEV!
      : process.env.REACT_APP_BASE_URL!) + "/api",
  socket_url: isDev
    ? process.env.REACT_APP_BASE_URL_DEV!
    : process.env.REACT_APP_BASE_URL!,
};
