import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import mainStore from "../../app/main.store";
import styles from "./Footer.module.scss";

function Footer() {
  const navigate = useNavigate();

  function handleLogout() {
    const isLogoutConfirmed = window.confirm(
      "If you logout you will lose access to this account it will be deleted permanently, are you sure?"
    );

    if (isLogoutConfirmed === true) {
      mainStore.socket.emit("logout", mainStore.user?._id);
      localStorage.removeItem("user");
      mainStore.setUser(undefined);
      navigate("/identity/login");
    }
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.container + " container"}>
        <span>
          <strong>OuChat</strong> 2023 © Copyright.
        </span>
        <span>
          Developed by{" "}
          <Link to={"https://ouddah.com"}>
            <strong>Ahmed Ouda</strong>
          </Link>
        </span>
      </div>
      <div className={styles.actions}>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </footer>
  );
}

export default observer(Footer);
